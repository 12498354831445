import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { SCORE_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  npsSettings: {
    id: '',
    is_active: false,
    organization_id: '',
    main_question: '',
    scale_answer: {
      low: '',
      high: '',
    },
    follow_up_set: 0,
    follow_up_2: '',
    follow_up_3: {
      follow_up_passive: '',
      follow_up_promoter: '',
      follow_up_detractor: '',
    },
    message_to_customer: '',
    header: '',
    thanks_message: '',
  },
  csatSettings: {
    id: '',
    is_active: false,
    organization_id: '',
    main_question: '',
    answer_options: {
      rating_1: '',
      rating_2: '',
      rating_3: '',
      rating_4: '',
      rating_5: '',
    },
    set_follow_up: 0,
    follow_up_2: '',
    follow_up_3: {
      follow_up_rating_1: null,
      follow_up_rating_2: null,
      follow_up_rating_3: null,
      follow_up_rating_4: null,
      follow_up_rating_5: null,
    },
    header: '',
    thanks_message: '',
    message_callback_csat: '',
  },
  cesSettings: {
    id: '',
    is_active: false,
    organization_id: '',
    main_question: '',
    scale_answer: {
      bottom_scale: '',
      top_scale: '',
    },
    set_follow_up: 0,
    follow_up_2: '',
    follow_up_3: {
      follow_up_bottom: '',
      follow_up_neutral: '',
      follow_up_top: '',
    },
    header: '',
    thanks_message: '',
    message_callback_ces: '',
  },
})

export const getters = {
  getField,
}

export const actions = {
  getCSATSettings({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${SCORE_URL.csat}/setting/${params}`, null, {})
        .subscribe(
          (res) => {
            if (res.data) {
              commit('UPDATE_CSAT_SETTINGS', res.data)
            }
            resolve(res)
          },
          (err) => {
            if (err?.error?.messages?.[0] === 'survey has been filled') {
              return resolve(err)
            }
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to retrieve CSAT settings. Please refresh' },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  getNPSSettings({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${SCORE_URL.nps}/setting/${params}`, null, {})
        .subscribe(
          (res) => {
            if (res.data) {
              commit('UPDATE_NPS_SETTINGS', res.data)
            }
            resolve(res)
          },
          (err) => {
            if (err?.error?.messages?.[0] === 'survey has been filled') {
              return resolve(err)
            }
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to retrieve NPS settings. Please refresh' },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  getCESSettings({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${SCORE_URL.ces}/setting/${params}`, null, {})
        .subscribe(
          (res) => {
            if (res.data) {
              commit('UPDATE_CES_SETTINGS', res.data)
              resolve(res)
            }
          },
          (err) => {
            if (err?.error?.messages?.[0] === 'survey has been filled') {
              return resolve(err)
            }
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to retrieve CES settings. Please refresh' },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
  submitNpsSurvey({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${SCORE_URL.nps}/add-survey`, params, {})
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => reject(err)
        )
    })
  },
  submitCsatSurvey({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${SCORE_URL.csat}/add-survey`, params, {})
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => reject(err)
        )
    })
  },
  submitCesSurvey({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${SCORE_URL.ces}/add-survey`, params, {})
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => reject(err)
        )
    })
  },
  submitSurvey({ commit, state, dispatch }, params) {
    const promises = []

    if (state.npsSettings?.is_active) {
      promises.push(
        dispatch('submitNpsSurvey', {
          survey_key: params.survey_key,
          score: params.nps,
          comments: params.npsComment,
        })
      )
    }

    if (state.csatSettings?.is_active) {
      promises.push(
        dispatch('submitCsatSurvey', {
          survey_key: params.survey_key,
          rating: params.csat,
          comments: params.csatComment,
        })
      )
    }

    if (state.cesSettings?.is_active) {
      promises.push(
        dispatch('submitCesSurvey', {
          survey_key: params.survey_key,
          rating: params.ces,
          comments: params.cesComment,
        })
      )
    }

    return Promise.all(promises)
  },
}

export const mutations = {
  updateField,
  UPDATE_NPS_SETTINGS(state, payload) {
    state.npsSettings = payload
  },
  UPDATE_CSAT_SETTINGS(state, payload) {
    state.csatSettings = payload
  },
  UPDATE_CES_SETTINGS(state, payload) {
    state.cesSettings = payload
  },
}
