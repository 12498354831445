import { getField, updateField } from 'vuex-map-fields'
import requests from '../requests'
import {
  DIVISION_URL,
  INTEGRATION_URL,
  CONTACT_MASKING,
} from '~/assets/variables/endpoints'

export const state = () => ({
  divisionList: [],
  detail: {
    name: '',
    supervisor: '',
    agents: [],
    contactMasking: [],
  },
  allIntegratedChannels: [],
  selectedIntegratedChannel: [],
  listOfUserDivision: [],
})

export const getters = {
  getField,
}

export const actions = {
  getDivisionList({ commit }, params) {
    // for infinite scroll
    if (this.$router.currentRoute.fullPath.includes('division'))
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'table', status: true },
        { root: true }
      )
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${DIVISION_URL}`,
          { ...params.parameter, is_counted: params.parameter.offset === 1 },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            if (this.$router.currentRoute.fullPath.includes('division'))
              commit(
                'layouts/UPDATE_CONTENTLOADING',
                { type: 'table', status: false },
                { root: true }
              )

            if (params.parameter.offset === 1) {
              commit(
                'infinite/SET_META',
                { data: res.meta, source: params.source },
                { root: true }
              )
            }
            commit('SET_DIVISION_LIST', res.data)

            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )

            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getDivisionListByPage({ commit }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${DIVISION_URL}`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            commit('UPDATE_DIVISION_LIST', res.data)

            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  addDivision({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${DIVISION_URL}/users`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  updateDivision({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${DIVISION_URL}/users`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  updateChannelDivision({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${DIVISION_URL}/${params.id}/channels`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  deleteDivision({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .deleteService(`${DIVISION_URL}/${params.id}`, null, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Deleted!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )

            // update division list
            const currentList = [...state.divisionList]
            const deleted = currentList.findIndex(
              (list) => list.id === params.id
            )
            if (deleted > -1) {
              currentList.splice(deleted, 1)
              commit('SET_DIVISION_LIST', currentList)
            }

            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getChannelOnDivision({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${DIVISION_URL}/${params.id}/channels`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getAllIntegrations({ commit, state }, params) {
    let b = {}
    if (params) {
      b = { target_channel: params }
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${INTEGRATION_URL}`,
          { ...b, all: true },
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_INTEGRATED_CHANNELS', res.data)
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getContactMasking({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      requests.whatsapp
        .getService(
          `${CONTACT_MASKING}/contact`,
          { ...params },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_CONTACT_MASKING', res.data)
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(res)
          },
          (err) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  setContactMasking({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      requests.whatsapp
        .postService(`${CONTACT_MASKING}/contact`, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(res)
          },
          (err) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  contactMaskingFlag({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      requests.whatsapp
        .postService(`${CONTACT_MASKING}/flag`, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            commit(
              'template/UPDATE_IS_CONTACT_MASKING',
              res.data?.is_contact_masking,
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  getListOfUserDivision({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${DIVISION_URL}/me`, payload, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_LIST_OF_USER_DIVISION', res.data)
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_DIVISION_LIST(state, payload) {
    payload = payload.map((obj) => ({ ...obj, value: obj.name }))
    state.divisionList = payload
  },
  UPDATE_DIVISION_LIST(state, payload) {
    state.divisionList = [...state.divisionList, ...payload]
  },
  SET_DIVISION_DETAIL(state, payload) {
    let supervisor = null
    let agents = []

    if (payload.users?.length) {
      supervisor = payload.users.find((user) => user.role === 'supervisor')
      agents = payload.users.filter((user) => user.role === 'agent')
    }

    const detail = {
      ...payload,
      supervisor,
      agents,
    }

    state.detail = detail
  },
  DELETE_AGENT_DIVISION(state, payload) {
    const agents = [...state.detail.agents]
    agents.splice(payload, 1)

    state.detail = {
      ...state.detail,
      agents,
    }
  },
  UPDATE_INTEGRATED_CHANNELS(state, payload) {
    state.allIntegratedChannels = payload
  },
  UPDATE_CONTACT_MASKING(state, payload) {
    state.contactMasking = payload
  },
  UPDATE_LIST_OF_USER_DIVISION(state, payload) {
    state.listOfUserDivision = payload
  },
}
