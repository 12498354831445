import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'
import _ from 'lodash-core'
import requests from '../requests'
import {
  ROOMS_URL,
  SEND_MESSAGE_URL,
  ROOMS_MESSAGE,
  ROOMS_NOTES,
  TOOLS_URL,
} from '@/assets/variables/endpoints'
import { conversationFormatter } from '~/utils/messaging/conversation'

export const state = () => ({
  formattedMessage: [],
  token: 'bearer UToO79GXGcA8s3s4oUG-9Mk2yIwVA-JYpnPDyZ0wDLQ',
  agentLists: [],
  roomSession: moment(),
  conversationInformation: {
    id: '',
    name: '',
    channel: '',
    email: '',
    phone: '',
    usageDetails: {
      created: '',
      last_seen: '',
      session_expired: '',
    },
    avatar: '',
    notes: '',
    tags: [],
    properties: [],
    agents: [],
    resolved: false,
    session: '',
    last_message: {
      text: '',
      type: '',
    },
    unread_count: 0,
    temporaryRoomId: '',
    roomHistory: [],
    msgHistorySearch: [],
  },
  broadcastDetail: {
    broadcast_name: 'Lorem Ipsum',
    message_template_name: 'lorem_ipsum',
    created_at: `${moment().subtract(37, 'hours').format()}`,
    status: 'delivered',
    message: {
      type: 'image',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
  },
  editMode: {
    email: false,
    notes: false,
  },
  payloadToSend: {},
  trigger: {
    image: false,
    document: false,
  },
  meta: {},
  message: {
    length: null,
    pop: 0,
  },
  status: {
    noConversation: false,
    noResults: false,
    error: false,
  },
  page: 1,
  messagePage: {
    before: 1,
    after: 1,
  },
  identifier: 1,
  conversationsFormatted: [],
  conversations: [],
  conversationHistories: [],
  query: {
    room: null,
    roomHistory: null,
  },
  scroll: {
    difference: null,
  },
  room: {
    unread: 1,
  },
  cursor: {
    isEncoded: false,
    value: null,
  },
  fromViewAll: false,
})

export const getters = {
  getField,
  getQueryRoom(state) {
    return state.query.room
  },
}

export const actions = {
  async sendMessage({ commit, dispatch, state }, params) {
    // Adjust the current params to the formatted Request
    await commit(`${params.type.toUpperCase()}_FORMATTER`, params)

    // Send message to API
    dispatch('sendToAPI', state.formattedMessage)
  },
  async sendToAPI({ commit, state, dispatch }, params) {
    // Create temporary ID using today's date
    const dateId = params.type + moment().format('DDMMYYYYHHmmSS')

    // Update to local conversation
    await commit(`UPDATE_${params.type.toUpperCase()}_CONVERSATION`, {
      ...params,
      local: true,
    })

    // Do request
    requests.whatsapp
      .postService(`${SEND_MESSAGE_URL}`, state.payloadToSend, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        async (res) => {
          // Update current conversation ID and status based on response
          const index = _.findIndex(state.conversations, { id: dateId })
          const data = {
            id: res.data.id,
            status: 'delivered',
            index,
          }

          // Update room if it's agent, because FCM is not correctly yet
          if (this.$auth.user.role === 'agent') {
            await commit(
              'inbox/UPDATE_ROOM',
              {
                conversationInformation: state.conversationInformation,
                sender_type: 'message',
                customMsg: {
                  type: params.type,
                  text: params.text,
                },
                unreadUpdate: 0,
              },
              { root: true }
            )
          }

          // Update to current conversation
          await commit('UPDATE_CONVERSATION_STATUS', data)
          await commit('inbox/READ_ROOM', res.data.room_id, { root: true })
        },
        async (err) => {
          await commit(
            'layouts/DISPLAY_TOAST',
            { message: 'Failed to send message. Please try again later' },
            { root: true }
          )
          const index = _.findIndex(state.conversations, { id: dateId })
          const data = {
            id: moment().format('DDMMYYYYHHmmSS'),
            status: 'failed',
            index,
            err,
          }

          // Update to current conversation
          await commit('UPDATE_CONVERSATION_STATUS', data)
          await commit('inbox/READ_ROOM', state.query.room, { root: true })
        }
      )
  },
  assignAgent({ commit, state, dispatch }, params) {
    const data = {
      id: params.room.id,
      user_id: params.agent.id,
    }
    let url
    let messageResp
    let messageFloat

    if (params.sender.role !== 'agent') {
      url = `${ROOMS_URL}/${params.room.id}/agents/${params.agent.id}`
      messageResp =
        '<b>' +
        params.agent.full_name +
        '</b> has been assigned to this conversation'
      messageFloat =
        params.agent.full_name + ' has been assigned to this conversation'
    } else {
      url = `${ROOMS_URL}/${params.room.id}/handover/${params.agent.id}`
      messageResp =
        '<b>' +
        params.sender.name +
        '</b> handed over this conversation to ' +
        '<b>' +
        params.agent.full_name +
        '</b>'
      messageFloat =
        params.sender.name +
        ' handed over this conversation to ' +
        params.agent.full_name
    }

    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(url, data, { Authorization: this.$auth.getToken('hub') })
        .subscribe(
          async (res) => {
            // Create temporary ID
            params.id = 'AGENT_' + moment().format('DDMMYYYYHHmmSS')

            // UPDATE ROOM STATUS
            await commit('UPDATE_ROOM_STATUS', 'assigned')

            // Update to room
            await commit(
              'inbox/UPDATE_ROOM',
              {
                conversationInformation: state.conversationInformation,
                sender_type: 'message',
                customMsg: { type: 'notification', text: messageResp },
                unreadUpdate: null,
              },
              { root: true }
            )

            // Update assigned agent to current conversation information
            await commit('ASSIGN_AGENT', res.data)

            // Update notification to conversation
            await commit('UPDATE_ASSIGNED_AGENT_NOTIFICATION', params)

            // Update to designated room on room panel
            await commit(
              'inbox/UPDATE_ASSIGNATION_ROOM',
              {
                room: params.room,
                sender: params.sender,
              },
              { root: true }
            )

            // Display notification
            commit(
              'layouts/DISPLAY_TOAST',
              { message: messageFloat },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: err.error.messages[0], err },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  removeAgent({ commit, state, dispatch }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .deleteService(
          `${ROOMS_URL}/${params.room.id}/agents/${params.agent.contact_able_id}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          async (res) => {
            // Create temporary ID
            params.id = 'AGENT_' + moment().format('DDMMYYYYHHmmSS')

            // UPDATE ROOM STATUS
            let status = ''
            if (state.conversationInformation.agents)
              status = state.conversationInformation.status
            await commit('UPDATE_ROOM_STATUS', status)

            // Update to room
            await commit(
              'inbox/UPDATE_ROOM',
              {
                conversationInformation: state.conversationInformation,
                sender_type: 'message',
                customMsg: {
                  type: 'notification',
                  text:
                    '<b>' +
                    params.agent.profile.name +
                    '</b> has been removed from this conversation by <b>' +
                    params.sender.name +
                    '</b>',
                },
                unreadUpdate: null,
              },
              { root: true }
            )

            // Update assigned agent to current conversation information
            await commit('REMOVE_AGENT', params.agent)

            // Update notification to conversation
            await commit('REMOVE_ASSIGNED_AGENT_NOTIFICATION', params)

            // Update to designated room on room panel
            await commit(
              'inbox/UPDATE_ASSIGNATION_ROOM',
              {
                room: params.room,
                sender: params.sender,
              },
              { root: true }
            )

            // Display notification
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message:
                  params.agent.profile.name +
                  ' has been removed from this conversation',
              },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: err.error.messages[0], err },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  resolveConversation({ commit, state, dispatch }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .putService(
          `${ROOMS_URL}/${params.room.id}/resolve`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          async (res) => {
            // Create temporary ID
            params.id = 'RESOLVE_' + moment().format('DDMMYYYYHHmmSS')

            // UPDATE ROOM STATUS
            await commit('UPDATE_ROOM_STATUS', 'resolved')

            // Update to room
            await commit(
              'inbox/UPDATE_ROOM',
              {
                conversationInformation: state.conversationInformation,
                sender_type: 'message',
                customMsg: {
                  type: 'notification',
                  text:
                    'Conversation has been resolved by ' +
                    params.agent.full_name,
                },
                unreadUpdate: null,
              },
              { root: true }
            )

            // Set conversation status resolve to true
            await commit('RESOLVE_CONV', true)

            // Update notification to conversation
            commit('UPDATE_RESOLVED_NOTIFICATION', params)

            // Display notification toast
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message:
                  'Conversation has been resolved by ' + params.agent.full_name,
              },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: err.error.messages[0], err },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  editConversationInformationNotes({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .postService(
          `${ROOMS_NOTES}/${params.id}`,
          { text: params.text },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('EDIT_CONVERSATION_INFORMATION_NOTES', res.data)
            commit('UPDATE_EDIT_MODE', {
              type: params.type,
              value: !state.editMode[params.type],
            })
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Successfully updated ' + params.type },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              {
                message:
                  'Failed to update ' +
                  params.type +
                  ', please try again later. ' +
                  err.error.messages[0],
              },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  getAgentAssignLists({ commit }, params) {
    requests.whatsapp
      .getService(
        `${ROOMS_URL}/${params.room_id}/agents/assignable`,
        params.payload,
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('UPDATE_AGENT_LISTS', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        }
      )
  },
  getConversation({ commit, state }, params) {
    return new Promise((resolve) => {
      const parameters = { limit: params.limit, offset: params.offset }

      if (state.cursor.isEncoded) {
        parameters.cursor = state.cursor.value
        parameters.cursor_direction = params.cursorDirection
      }

      requests.whatsapp
        .getService(`${ROOMS_MESSAGE}/related/${params.room}`, parameters, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (state.query.room) {
              if (
                res.data.length > 0 &&
                res.data[0].room_id === state.query.room
              ) {
                commit('REMOVE_CONVERSATION', res.data)
                commit('CONVERSATION_FORMATTER', state.conversationsFormatted)
                commit('UPDATE_CONVERSATION', state.conversationsFormatted)
              }
              commit('SET_META', res.meta)
            } else {
              commit('REMOVE_CONVERSATION', res.data)
              commit('CONVERSATION_FORMATTER', state.conversationsFormatted)
              commit('UPDATE_CONVERSATION', state.conversationsFormatted)
              commit('SET_META', res.meta)
            }
            resolve(res.data.length)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err?.error?.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('conversation/UPDATE_QUERY_ROOM', '', { root: true })
            resolve(false)
          }
        )
    })
  },
  getConversationByCursor({ commit, state }, params) {
    return new Promise((resolve) => {
      const parameters = {
        limit: params.limit,
        cursor: state.meta?.pagination?.cursor?.prev
          ? state.meta.pagination.cursor.prev
          : params.cursor,
        cursor_direction: params.cursorDirection,
      }

      requests.whatsapp
        .getService(`${ROOMS_MESSAGE}/related/${params.room}`, parameters, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (state.query.room) {
              if (
                res.data.length > 0 &&
                res.data[0].room_id === state.query.room
              ) {
                commit('REMOVE_CONVERSATION', res.data)
                commit('CONVERSATION_FORMATTER', state.conversationsFormatted)
                commit('UPDATE_CONVERSATION', state.conversationsFormatted)
              }
              commit('SET_META', res.meta)
            } else {
              commit('REMOVE_CONVERSATION', res.data)
              commit('CONVERSATION_FORMATTER', state.conversationsFormatted)
              commit('UPDATE_CONVERSATION', state.conversationsFormatted)
              commit('SET_META', res.meta)
            }
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err?.error?.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('conversation/UPDATE_QUERY_ROOM', '', { root: true })
            resolve(false)
          }
        )
    })
  },
  async getConversationInformation({ commit, state, dispatch }, params) {
    if (state.conversations.length === 0) {
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'content2', status: true },
        { root: true }
      )
    }
    await commit('REMOVE_CONV_INFORMATION')
    return new Promise((resolve, reject) => {
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'content3', status: true },
        { root: true }
      )
      commit('UPDATE_EDIT_MODE', { type: 'email', value: false })
      commit('UPDATE_EDIT_MODE', { type: 'notes', value: false })
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/${params}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          async (res) => {
            await commit('UPDATE_CONVERSATION_INFORMATION', res.data)
            commit('EMPTY_TAG', [])
            for (const x in res.data.tags) {
              dispatch(
                'chat/recoverTag',
                { tag: res.data.tags[x] },
                { root: true }
              )
            }
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content2', status: false },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content3', status: false },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content2', status: false },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content3', status: false },
              { root: true }
            )
            commit('conversation/UPDATE_QUERY_ROOM', '', { root: true })
            reject(err)
          }
        )
    })
  },
  updateTags({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .postService(
          `${ROOMS_URL}/${params.id}/tags`,
          { tag: params.tag },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Successfully updated room tags' },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            const index = _.findIndex(state.conversationInformation.tags, {
              name: params.tag,
            })
            commit('REMOVE_TAG', index)
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to update room tags' },
              { root: true }
            )
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  updateUnreadCountRoom({ commit, state }, params) {
    requests.whatsapp
      .putService(`${ROOMS_URL}/${params.id}/mark_all_as_read`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('UPDATE_UNREAD_IN_ROOM', 0)
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  removeTags({ commit, state, dispatch }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .deleteService(
          `${ROOMS_URL}/${params.id}/tags`,
          { tag: params.tag },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Successfully updated room tags' },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            dispatch('chat/recoverTag', { tag: params.tag }, { root: true })
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to update room tags' },
              { root: true }
            )
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  getParticipantLists({ commit }, params) {
    requests.whatsapp
      .getService(
        `${ROOMS_URL}/${params.id}/participants`,
        { type: params.type },
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('UPDATE_PARTICIPANT_LIST', res.data)
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getAgentLists({ commit, state }, params) {
    requests.whatsapp
      .getService(
        `${ROOMS_URL}/${params.id}/participants`,
        { type: 'agent' },
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('UPDATE_PARTICIPANT_LIST', res.data)
          const isParticipant = _.findIndex(
            state.conversationInformation.agents,
            { contact_able_id: this.$auth.user.id }
          )
          if (isParticipant > -1) {
            commit(
              'layouts/UPDATE_NOTIFICATION_READ',
              {
                type: params.selected_notification_menu,
                room: {
                  id: params.id,
                },
              },
              { root: true }
            )
          }
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getRecentRoomHistory({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${ROOMS_URL}/${params.id}/histories`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getRoomHistories({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${ROOMS_URL}/${params.id}/histories`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/SET_META', res.meta, { root: true })
            commit(
              params.offset > 1 ? 'PUSH_ROOM_HISTORY' : 'UPDATE_ROOM_HISTORY',
              res.data
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  getMessageHistories({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${ROOMS_MESSAGE}/${params.id}/histories`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/SET_META', res.meta, { root: true })
            commit('UPDATE_MSG_HISTORY_SEARCH', res.data)
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(false)
          }
        )
    })
  },
  encodeCursor({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${TOOLS_URL}/encode_cursor`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_CURSOR_VALUE', res.data.cursor_at)
            // commit('SET_CURSOR_VALUE', 'MTY0NzQzMDE4Ni4w')
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  searchConversationHistory({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${SEND_MESSAGE_URL}/search`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  TEXT_FORMATTER(state, payload) {
    payload.created_at = moment().toISOString()
    state.formattedMessage = payload
    payload = _.omit(payload, ['created_at'])
    state.payloadToSend = payload
  },
  IMAGE_FORMATTER(state, payload) {
    payload.created_at = moment().toISOString()
    state.formattedMessage = payload
    const formData = new FormData()
    formData.append('room_id', payload.room_id)
    formData.append('type', payload.type)
    formData.append('file', payload.file)
    if (payload.text) formData.append('text', payload.text)
    state.payloadToSend = formData
  },
  DOCUMENT_FORMATTER(state, payload) {
    payload.created_at = moment().toISOString()
    state.formattedMessage = payload
    const formData = new FormData()
    formData.append('room_id', payload.room_id)
    formData.append('type', payload.type)
    formData.append('file', payload.file)
    if (payload.text) formData.append('text', payload.text)
    state.payloadToSend = formData
  },
  CONVERSATION_FORMATTER(state, payload) {
    const message = conversationFormatter(
      payload,
      state.conversationInformation.channel
    )
    state.conversationsFormatted = message
  },
  PUSH_CONVERSATION(state, payload) {
    const index = _.findIndex(state.conversations, { local: true })
    if (index >= 0) {
      state.conversations.splice(index, 1)
      state.conversations = [
        ...state.conversations.slice(0, index),
        payload,
        ...state.conversations.slice(index),
      ]
    } else if (
      index < 0 &&
      _.findIndex(state.conversations, { id: payload.id }) < 0
    ) {
      state.conversations.push(payload)
    }
  },
  UPDATE_TEXT_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      localVar.id = payload.type + moment().format('DDMMYYYYHHmmSS')
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }
    const data = {
      id: localVar.id,
      message: {
        type: payload.type,
        text: payload.text,
      },
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: '',
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
    }
    state.conversations.push(data)
  },
  UPDATE_IMAGE_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      localVar.id = payload.type + moment().format('DDMMYYYYHHmmSS')
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }
    const data = {
      id: localVar.id,
      message: {
        type: payload.type,
        text: payload.text,
        file: payload.file,
        url: payload.url,
      },
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: '',
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
    }
    state.trigger.image = true
    state.conversations.push(data)
  },
  UPDATE_DOCUMENT_CONVERSATION(state, payload) {
    const localVar = {}
    if (payload.local) {
      localVar.id = payload.type + moment().format('DDMMYYYYHHmmSS')
      localVar.status = 'pending'
    } else {
      localVar.id = payload.id
      localVar.status = payload.status
    }
    const data = {
      id: localVar.id,
      message: {
        type: payload.type,
        text: payload.text,
        file: payload.file,
        url: payload.url,
      },
      created_at: moment().toISOString(),
      type: 'message',
      sender: {
        id: this.$auth.user.id,
        name: this.$auth.user.full_name,
        avatar: {
          small: '',
        },
      },
      status: localVar.status,
      room_id: payload.room_id,
      participant_type: 'agent',
      local: true,
    }
    state.trigger.document = true
    state.conversations.push(data)
  },
  UPDATE_CONVERSATION_STATUS(state, payload) {
    const index = _.findIndex(state.conversations, { id: payload.id })

    if (index < 0 && payload.id) {
      state.conversations[payload.index].id = payload.id
      state.conversations[payload.index].status = payload.status
      state.conversations[payload.index].local = false
    }
  },
  UPDATE_ASSIGNED_AGENT_NOTIFICATION(state, payload) {
    const index = _.findIndex(state.conversations, { id: payload.id })
    if (index < 0) {
      const data = {
        id: payload.id,
        message: {
          type: '',
          text:
            '<b>' +
            payload.agent.full_name +
            '</b> has been assigned to this conversation',
        },
        created_at: moment().toISOString(),
        type: 'system',
        sender: payload.sender,
        status: '',
        agent: payload.agent,
      }
      state.conversations.push(data)
    }
  },
  REMOVE_ASSIGNED_AGENT_NOTIFICATION(state, payload) {
    const index = _.findIndex(state.conversations, { id: payload.id })
    if (index < 0) {
      const data = {
        id: payload.id,
        message: {
          type: '',
          text:
            '<b>' +
            payload.agent.profile.name +
            '</b> has been removed from this conversation by <b>' +
            payload.sender.name +
            '</b>',
        },
        created_at: moment().toISOString(),
        type: 'system',
        sender: payload.sender,
        status: '',
        agent: payload.agent,
      }
      state.conversations.push(data)
    }
  },
  UPDATE_RESOLVED_NOTIFICATION(state, payload) {
    const index = _.findIndex(state.conversations, { id: payload.id })
    if (index < 0) {
      const data = {
        id: payload.id,
        message: {
          type: '',
          text: 'Conversation has been resolved by ' + payload.agent.full_name,
        },
        created_at: moment().toISOString(),
        type: 'system',
        sender: {
          id: this.$auth.user.id,
          name: this.$auth.user.full_name,
          avatar: {
            small: '',
          },
        },
        status: '',
        agent: payload.agent,
      }

      state.conversations.push(data)
    }
  },
  ASSIGN_AGENT(state, payload) {
    const index = _.findIndex(state.conversationInformation.agents, {
      id: payload.id,
    })
    if (index < 0) {
      state.conversationInformation.agents.push(payload)
    }
  },
  REMOVE_AGENT(state, payload) {
    const index = _.findIndex(state.conversationInformation.agents, {
      id: payload.id,
    })
    if (index >= 0) {
      state.conversationInformation.agents.splice(index, 1)
    }
  },
  UPDATE_CONVERSATION_INFORMATION(state, payload) {
    state.conversationInformation.id = payload.id
    state.conversationInformation.name = payload.name
    state.conversationInformation.channel = payload.channel
    state.conversationInformation.email = payload.email
    state.conversationInformation.phone = payload.account_uniq_id
    state.conversationInformation.usageDetails.created = payload.created_at
    state.conversationInformation.usageDetails.last_seen = payload.updated_at
    state.conversationInformation.usageDetails.session_expired = payload.session
    state.conversationInformation.avatar = payload.avatar
      ? payload.avatar
      : null
    state.conversationInformation.notes =
      payload.note === null ? payload.note : payload.note.text
    state.conversationInformation.tags = payload.tags
    state.conversationInformation.session = payload.session
    state.conversationInformation.resolved = payload.status === 'resolved'
    state.conversationInformation.status = payload.status
    state.conversationInformation.unread_count = payload.unread_count
    if (payload.session !== 'expired')
      state.roomSession = new Date(payload.session_at)
  },
  UPDATE_ROOM_STATUS(state, payload) {
    state.conversationInformation.status = payload
  },
  EDIT_CONVERSATION_INFORMATION(state, payload) {
    state.conversationInformation[payload.type] = payload.value[payload.type]
  },
  EDIT_CONVERSATION_INFORMATION_NOTES(state, payload) {
    state.conversationInformation.notes = payload.note.text
  },
  UPDATE_AGENT_LISTS(state, payload) {
    const agents = payload
    state.agentLists = agents
  },
  UPDATE_MESSAGE_META(state, payload) {
    state.message = {
      length: payload.length,
      pop: payload.pop,
    }
  },
  REMOVE_CONVERSATION(state, payload) {
    if (state.message.length) {
      payload.splice(0, state.message.pop)
    }
    state.conversationsFormatted = payload
  },
  UPDATE_CONVERSATION(state, payload) {
    const merged = [...state.conversations, ...payload]

    const unique = [...new Map(merged.map((room) => [room.id, room])).values()]

    state.conversations = _.sortBy(unique, (c) => c.created_at)
  },
  UPDATE_TRIGGER(state, payload) {
    state.trigger = {
      image: payload.image,
      document: payload.document,
    }
  },
  SET_META(state, payload) {
    state.meta = payload
  },
  SET_INITIAL_CONDITION(state) {
    state.status = {
      noConversation: false,
      noResults: false,
      error: false,
    }
    state.page = 1
    state.identifier++
    state.conversations = []
    state.message = {
      length: null,
      pop: 0,
    }
    state.meta = {}
    state.scroll.difference = null
    state.room.unread = 0
  },
  UPDATE_PAGE(state, payload) {
    state.page = payload
  },
  UPDATE_MESSAGE_PAGE(state, payload) {
    state.messagePage[payload.direction] = payload.page
  },
  UPDATE_STATUS(state, payload) {
    state.status.noConversation = payload.noConversation
    state.status.noResults = payload.noResults
    state.status.error = payload.error
  },
  UPDATE_INFINITE_LOADING(state, payload) {
    state.infinite = state.infinite + payload
  },
  UPDATE_SEND_STATUS(state, payload) {
    state.conversations[state.conversations.length - 1].status = payload
  },
  RESOLVE_CONV(state, payload) {
    state.conversationInformation.resolved = payload
  },
  UPDATE_CONV_BY_SCROLLING(state, payload) {
    for (const x in payload) {
      state.conversations.unshift(payload[x])
    }
  },
  UPDATE_HISTORY_BY_SCROLLING(state, payload) {
    for (const x in payload) {
      state.conversationInformation.roomHistory.push(payload[x])
    }
  },
  UPDATE_TAGS(state, payload) {
    state.conversationInformation.tags.push(payload)
  },
  UPDATE_EDIT_MODE(state, payload) {
    state.editMode[payload.type] = payload.value
  },
  UPDATE_QUERY_ROOM(state, payload) {
    state.query.room = payload
  },
  REMOVE_TAG(state, payload) {
    state.conversationInformation.tags.splice(payload, 1)
  },
  EMPTY_TAG(state, payload) {
    state.conversationInformation.tags = []
  },
  UPDATE_PARTICIPANT_LIST(state, payload) {
    state.conversationInformation.agents = payload
  },
  UPDATE_SCROLL(state, payload) {
    state.scroll.difference = payload
  },
  UPDATE_UNREAD_IN_ROOM(state, payload) {
    state.room.unread = payload
  },
  UPDATE_CONVERSATION_SESSION_STATUS(state, payload) {
    state.conversationInformation.session = payload
  },
  REMOVE_CONV_INFORMATION(state) {
    state.conversationInformation = {
      id: '',
      name: '',
      channel: '',
      email: '',
      phone: '',
      usageDetails: {
        created: '',
        last_seen: '',
        session_expired: '',
      },
      avatar: '',
      notes: '',
      tags: [],
      properties: [],
      agents: [],
      resolved: false,
      session: '',
      last_message: {
        text: '',
        type: '',
      },
      unread_count: 0,
      roomHistory: [],
    }
  },
  PUSH_ROOM_HISTORY(state, payload) {
    state.conversationInformation.roomHistory.push(...payload)
  },
  UPDATE_ROOM_HISTORY(state, payload) {
    state.conversationInformation.roomHistory = payload
  },
  UPDATE_MSG_HISTORY_SEARCH(state, payload) {
    state.conversationInformation.msgHistorySearch.push(...payload)
  },
  CREATE_TEMPORARY_ID(state, payload) {
    state.temporaryRoomId = payload
  },
  SET_DEFAULT_ROOM_LISTS(state, payload) {
    state.conversationInformation.roomHistory = []
  },
  SET_DEFAULT_MSG_HISTORY_LISTS(state, payload) {
    state.conversationInformation.msgHistorySearch = []
  },
  UPDATE_CONVERSATION_AVATAR(state, payload) {
    state.conversationInformation.avatar = payload.avatar
      ? payload.avatar
      : null
  },
  SET_CURSOR_VALUE(state, payload) {
    state.cursor.value = payload
  },
  SET_CURSOR_ENCODE(state, payload) {
    state.cursor.isEncoded = payload
  },
  UPDATE_CONVERSATION_EXTERNAL_ID(state, payload) {
    const index = _.findIndex(state.conversations, { id: payload.id })

    if (index >= 0)
      state.conversations[payload.index].external_id = payload.value
  },
  UPDATE_IS_HISTORY_FROM_VIEW_ALL(state, payload) {
    state.fromViewAll = payload
  },
}
