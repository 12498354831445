import { getField, updateField } from 'vuex-map-fields'
import { INTEGRATION_QONTAK_CRM } from '~/assets/variables/endpoints'
import requests from '~/requests'

export const state = () => ({
  ticket_properties: [],
  ticket_stages: [],
  crm_user: null,
  leads_list: [],
  companies_list: [],
  products_list: [],
  task_list: [],
})

export const getters = {
  getField,
}

const CRMV1 = process.env.CRM_URL

const mapTicketValue = (property) => {
  if (
    property.type === 'Dropdown select' ||
    property.name === 'crm_company_id'
  ) {
    return {
      ...property,
      value:
        property.value?.id && typeof property.value === 'object'
          ? `${property.value.id}`
          : `${property.value}`,
    }
  }
  if (
    property.type === 'Multiple select' ||
    property.name === 'crm_lead_ids' ||
    property.name === 'crm_product_ids' ||
    property.name === 'crm_task_ids'
  ) {
    return {
      ...property,
      value:
        property.value?.length && typeof property.value === 'object'
          ? `${property.value.map((option) => option.id)}`
          : '',
    }
  }
  return property
}

export const convertChecklistToValue = (checklist) => {
  return checklist
    .filter((c) => c.completed)
    .map((c) => c.id || c.crm_additional_option_id)
    .join(',')
}

export const actions = {
  saveTicket({ commit, state, dispatch }, params) {
    const requestBody = {
      crm_properties: state.ticket_properties.map((property) => {
        return mapTicketValue({ ...property, property_id: null })
      }),
      room_id: params.roomId,
      embed: true,
    }

    return this.$axios
      .post(`${CRMV1}/api/mobile/v2.8/tickets`, requestBody, {
        headers: {
          Authorization: params.crmToken,
        },
      })
      .then((res) => {
        const ticket = res.data.response
        dispatch('postLogNotes', {
          crmToken: params.crmToken,
          room_id: params.roomId,
          crm_ticket_id: ticket.id,
        })
        return dispatch('postTicketCreate', {
          room_id: params.roomId,
          crm_ticket_name: ticket.name,
          crm_ticket_id: ticket.id,
          crm_unique_ticket_id: ticket.unique_ticket_id,
        })
      })
      .then((result) => {
        return result
      })
      .catch((err) => {
        commit(
          'layouts/UPDATE_NOTIFICATION',
          {
            display: true,
            type: 'failed',
            message: 'Error!',
            item: err?.error?.messages?.[0] || 'Something went wrong',
            callback: {
              text: 'OK',
              method: 'closeNotification',
            },
          },
          { root: true }
        )
      })
  },

  getTicketStages({ commit, state }, params) {
    return this.$axios
      .get(
        `${CRMV1}/api/mobile/v2.8/ticket_pipelines/${params.pipeline_id}/ticket_stages`,
        {
          headers: {
            Authorization: params.crmToken,
          },
        }
      )
      .then((res) => {
        commit('SET_TICKET_STAGES', res.data.response)
        return res.data
      })
  },

  getCurrentUser({ commit, state }, params) {
    return this.$axios
      .get(`${CRMV1}/api/mobile/v2.7/users/me`, {
        headers: {
          Authorization: params.crmToken,
        },
      })
      .then((res) => {
        commit('SET_CRM_USER', res.data.response)
        return res.data.response
      })
      .catch(() => {})
  },

  getPipelines({ commit, state }, params) {
    return this.$axios
      .get(`${CRMV1}/api/mobile/v2.8/ticket_pipelines`, {
        headers: {
          Authorization: params.crmToken,
        },
      })
      .then((res) => {
        return res.data
      })
  },
  getTicketProperties({ commit, state }, params) {
    return this.$axios
      .get(`${CRMV1}/api/mobile/v2.8/tickets/ticket_properties`, {
        headers: {
          Authorization: params.crmToken,
        },
      })
      .then((res) => {
        commit('SET_TICKET_PROPERTIES', res.data.response.crm_properties)
        return res.data
      })
      .catch(() => {})
  },
  getLeadsList({ commit }, params) {
    return this.$axios
      .get(
        `${CRMV1}/api/mobile/v2.7/crm/leads?${new URLSearchParams({
          name: params.name || '',
        }).toString()}`,
        {
          headers: {
            Authorization: params.crmToken,
          },
        }
      )
      .then((res) => {
        commit('SET_LEADS_LIST', res.data.response)
      })
  },
  getProductList({ commit }, params) {
    return this.$axios
      .get(
        `${CRMV1}/api/mobile/v2.7/crm/products?${
          !params.query
            ? ''
            : new URLSearchParams({
                query: params.query,
              }).toString()
        }`,
        {
          headers: {
            Authorization: params.crmToken,
          },
        }
      )
      .then((res) => {
        commit('SET_PRODUCTS_LIST', res.data.response)
      })
  },
  getCompanyList({ commit }, params) {
    return this.$axios
      .get(
        `${CRMV1}/api/mobile/v2.7/crm/companies?${new URLSearchParams({
          name: params.name || '',
        })}`,
        {
          headers: {
            Authorization: params.crmToken,
          },
        }
      )
      .then((res) => {
        commit('SET_COMPANY_LIST', res.data.response)
      })
  },
  getTaskList({ commit }, params) {
    return this.$axios
      .get(
        `${CRMV1}/api/mobile/v2.7/crm/tasks?${new URLSearchParams({
          query: params.query || '',
        })}`,
        {
          headers: {
            Authorization: params.crmToken,
          },
        }
      )
      .then((res) => {
        commit('SET_TASK_LIST', res.data.response)
      })
  },
  postLogNotes({ commit }, params) {
    const requestBody = {
      note: `<p>Link to <a target="_blank" rel="noopener noreferrer" href="${process.env.BASE_URL}/inbox?room=${params.room_id}">Qontak Chat</a></p>`,
      ticket_id: params.crm_ticket_id,
      crm_checkin: null,
      crm_note_images: [],
      offline: false,
    }
    return this.$axios.post(
      `${CRMV1}/api/mobile/v2.8/crm/log/notes`,
      requestBody,
      {
        headers: {
          Authorization: params.crmToken,
        },
      }
    )
  },
  postTicketCreate({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${INTEGRATION_QONTAK_CRM}/ticket/create`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_LEADS_LIST(state, payload) {
    state.leads_list = payload
  },
  SET_COMPANY_LIST(state, payload) {
    state.companies_list = payload
  },
  SET_PRODUCTS_LIST(state, payload) {
    state.products_list = payload
  },
  SET_TASK_LIST(state, payload) {
    state.task_list = payload
  },
  EDIT_TEMPLATE_VALUE(state, payload) {
    const propertiesIdx = state.ticket_properties.findIndex(
      (p) => p.name === payload.index.name
    )

    if (payload.index.type === 'Checklist') {
      const selectedValue = payload.value.split(',')

      state.ticket_properties[propertiesIdx].dropdown.map((check) => {
        const found = selectedValue.find(
          (selectedId) =>
            parseInt(selectedId) === check.crm_additional_option_id ||
            parseInt(selectedId) === check.id
        )

        check.completed = found ? true : null

        return check
      })
      state.ticket_properties[propertiesIdx].value = convertChecklistToValue(
        state.ticket_properties[propertiesIdx].dropdown
      )
    } else {
      state.ticket_properties[propertiesIdx].value = payload.value
    }
  },
  SET_TICKET_PROPERTIES(state, payload) {
    const EXCLUDED_TYPES = ['gps', 'Signature', 'Photo']
    state.ticket_properties = payload.filter(
      (p) => !EXCLUDED_TYPES.includes(p.type)
    )
  },
  SET_TICKET_STAGES(state, payload) {
    state.ticket_stages = payload
  },
  SET_CRM_USER(state, payload) {
    state.crm_user = payload
  },
}
